.lf-spinner {
  position: relative;
  display: inline-block;
  background-color: #fff;
  border: 5px solid #fff;
  border-radius: 50%;
  -webkit-transform: rotate(263deg);
  transform: rotate(263deg);
  -ms-animation: IEfallback 1.9s infinite linear;
}
.lf-spinner svg {
  display: block;
  width: 60px;
  height: 60px;
}
.lf-spinner .lf-spinner-dash {
  stroke-dasharray: 240, 60;
  stroke-dashoffset: -6;
  -ms-stroke-dasharray: 240, 60;
  -ms-stroke-dashoffset: -6;
  stroke: #e30613;
  fill: transparent;
  stroke-width: 9;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  animation: spinnerDrawDash 1.9s infinite cubic-bezier(.53, 0, .49, 1);
  -webkit-animation: spinnerDrawDash 1.9s infinite cubic-bezier(.53, 0, .49, 1);
}
.lf-spinner .static-circle {
  fill: #fff;
  stroke-dasharray: 314;
  stroke: #005aa0;
  stroke-width: 8;
}

.lf-spinner .lf-spinner-back-line,
.lf-spinner .lf-spinner-forward-line {
    stroke: #fff;
    stroke-width: 6px;
    -webkit-transform: rotate(52deg);
    transform: rotate(52deg);
    transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50% !important;
    -webkit-animation: forwardLineRotate 1.9s infinite cubic-bezier(.51, .04, .46, .94);
    animation: forwardLineRotate 1.9s infinite cubic-bezier(.51, .04, .46, .94);
}

.lf-spinner .lf-spinner-back-line {
  stroke: #fff;
  -webkit-transform: rotate(7deg);
  transform: rotate(7deg);
  -webkit-animation: backLineRotate 1.9s infinite cubic-bezier(.53, 0, .49, 1);
  animation: backLineRotate 1.9s infinite cubic-bezier(.53, 0, .49, 1);
} 
.lf-spinner-sm {
  position: relative;
  display: inline-block;
  background-color: #fff;
  border: 5px solid #fff;
  border-radius: 50%;
  -webkit-transform: rotate(263deg);
  transform: rotate(263deg);
  -ms-animation: IEfallback 1.9s infinite linear;
}
.lf-spinner-sm svg {
  display: block;
  width: 40px;
  height: 40px;
}
.lf-spinner-sm .lf-spinner-dash {
  stroke-dasharray: 240, 60;
  stroke-dashoffset: -6;
  -ms-stroke-dasharray: 240, 60;
  -ms-stroke-dashoffset: -6;
  stroke: #e30613;
  fill: transparent;
  stroke-width: 9;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  animation: spinnerDrawDash 1.9s infinite cubic-bezier(.53, 0, .49, 1);
  -webkit-animation: spinnerDrawDash 1.9s infinite cubic-bezier(.53, 0, .49, 1);
}
.lf-spinner-sm .static-circle {
  fill: #fff;
  stroke-dasharray: 314;
  stroke: #005aa0;
  stroke-width: 8;
}
.lf-spinner-sm .lf-spinner-back-line,
.lf-spinner-sm .lf-spinner-forward-line {
  stroke: #fff;
  stroke-width: 6px;
  -webkit-transform: rotate(52deg);
  transform: rotate(52deg);
  -ms-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50% !important;
  -webkit-transform-origin: center left;
  -webkit-animation: forwardLineRotate 1.9s infinite
    cubic-bezier(.51, .04, .46, .94);
  animation: forwardLineRotate 1.9s infinite cubic-bezier(.51, .04, .46, .94);
}
.lf-spinner-sm .lf-spinner-back-line {
  stroke: #fff;
  -webkit-transform: rotate(7deg);
  transform: rotate(7deg);
  -webkit-animation: backLineRotate 1.9s infinite cubic-bezier(.53, 0, .49, 1);
  animation: backLineRotate 1.9s infinite cubic-bezier(.53, 0, .49, 1);
}
.lf-spinner-xs {
  position: relative;
  display: inline-block;
  background-color: #fff;
  border: 5px solid #fff;
  border-radius: 50%;
  -webkit-transform: rotate(263deg);
  transform: rotate(263deg);
  -ms-animation: IEfallback 1.9s infinite linear;
}
.lf-spinner-xs svg {
  display: block;
  width: 25px;
  height: 25px;
}
.lf-spinner-xs .lf-spinner-dash {
  stroke-dasharray: 240, 60;
  stroke-dashoffset: -6;
  -ms-stroke-dasharray: 240, 60;
  -ms-stroke-dashoffset: -6;
  stroke: #e30613;
  fill: transparent;
  stroke-width: 9;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  animation: spinnerDrawDash 1.9s infinite cubic-bezier(.53, 0, .49, 1);
  -webkit-animation: spinnerDrawDash 1.9s infinite cubic-bezier(.53, 0, .49, 1);
}
.lf-spinner-xs .static-circle {
  fill: #fff;
  stroke-dasharray: 314;
  stroke: #005aa0;
  stroke-width: 8;
}
.lf-spinner-xs .lf-spinner-back-line,
.lf-spinner-xs .lf-spinner-forward-line {
  stroke: #fff;
  stroke-width: 6px;
  -webkit-transform: rotate(52deg);
  transform: rotate(52deg);
  -ms-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50% !important;
  -webkit-transform-origin: center left;
  -webkit-animation: forwardLineRotate 1.9s infinite
    cubic-bezier(.51, .04, .46, .94);
  animation: forwardLineRotate 1.9s infinite cubic-bezier(.51, .04, .46, .94);
}
.lf-spinner-xs .lf-spinner-back-line {
  stroke: #fff;
  -webkit-transform: rotate(7deg);
  transform: rotate(7deg);
  -webkit-animation: backLineRotate 1.9s infinite cubic-bezier(.53, 0, .49, 1);
  animation: backLineRotate 1.9s infinite cubic-bezier(.53, 0, .49, 1);
}
.lf-spinner-neg {
  position: relative;
  display: inline-block;
  background-color: transparent;
  border: 5px solid transparent;
  border-radius: 50%;
  -webkit-transform: rotate(263deg);
  transform: rotate(263deg);
  -ms-animation: IEfallback 1.9s infinite linear;
}
.lf-spinner-neg svg {
  display: block;
  width: 60px;
  height: 60px;
}
.lf-spinner-neg .lf-spinner-dash {
  stroke-dasharray: 240, 60;
  stroke-dashoffset: -6;
  -ms-stroke-dasharray: 240, 60;
  -ms-stroke-dashoffset: -6;
  stroke: #e30613;
  fill: transparent;
  stroke-width: 9;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  animation: spinnerDrawDash 1.9s infinite cubic-bezier(.53, 0, .49, 1);
  -webkit-animation: spinnerDrawDash 1.9s infinite cubic-bezier(.53, 0, .49, 1);
}
.lf-spinner-neg .static-circle {
  fill: transparent;
  stroke-dasharray: 314;
  stroke: transparent;
  stroke-width: 8;
}
.lf-spinner-neg .lf-spinner-back-line,
.lf-spinner-neg .lf-spinner-forward-line {
  stroke: #fff;
  stroke-width: 6px;
  -webkit-transform: rotate(52deg);
  transform: rotate(52deg);
  -ms-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50% !important;
  -webkit-transform-origin: center left;
  -webkit-animation: forwardLineRotate 1.9s infinite
    cubic-bezier(.51, .04, .46, .94);
  animation: forwardLineRotate 1.9s infinite cubic-bezier(.51, .04, .46, .94);
}
.lf-spinner-neg .lf-spinner-back-line {
  stroke: #fff;
  -webkit-transform: rotate(7deg);
  transform: rotate(7deg);
  -webkit-animation: backLineRotate 1.9s infinite cubic-bezier(.53, 0, .49, 1);
  animation: backLineRotate 1.9s infinite cubic-bezier(.53, 0, .49, 1);
}
.lf-spinner-neg-sm {
  position: relative;
  display: inline-block;
  background-color: transparent;
  border: 5px solid transparent;
  border-radius: 50%;
  -webkit-transform: rotate(263deg);
  transform: rotate(263deg);
  -ms-animation: IEfallback 1.9s infinite linear;
}
.lf-spinner-neg-sm svg {
  display: block;
  width: 40px;
  height: 40px;
}
.lf-spinner-neg-sm .lf-spinner-dash {
  stroke-dasharray: 240, 60;
  stroke-dashoffset: -6;
  -ms-stroke-dasharray: 240, 60;
  -ms-stroke-dashoffset: -6;
  stroke: #e30613;
  fill: transparent;
  stroke-width: 9;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  animation: spinnerDrawDash 1.9s infinite cubic-bezier(.53, 0, .49, 1);
  -webkit-animation: spinnerDrawDash 1.9s infinite cubic-bezier(.53, 0, .49, 1);
}
.lf-spinner-neg-sm .static-circle {
  fill: transparent;
  stroke-dasharray: 314;
  stroke: transparent;
  stroke-width: 8;
}
.lf-spinner-neg-sm .lf-spinner-back-line,
.lf-spinner-neg-sm .lf-spinner-forward-line {
  stroke: #fff;
  stroke-width: 6px;
  -webkit-transform: rotate(52deg);
  transform: rotate(52deg);
  -ms-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50% !important;
  -webkit-transform-origin: center left;
  -webkit-animation: forwardLineRotate 1.9s infinite
    cubic-bezier(.51, .04, .46, .94);
  animation: forwardLineRotate 1.9s infinite cubic-bezier(.51, .04, .46, .94);
}
.lf-spinner-neg-sm .lf-spinner-back-line {
  stroke: #fff;
  -webkit-transform: rotate(7deg);
  transform: rotate(7deg);
  -webkit-animation: backLineRotate 1.9s infinite cubic-bezier(.53, 0, .49, 1);
  animation: backLineRotate 1.9s infinite cubic-bezier(.53, 0, .49, 1);
}
.lf-spinner-neg-xs {
  position: relative;
  display: inline-block;
  background-color: transparent;
  border: 5px solid transparent;
  border-radius: 50%;
  -webkit-transform: rotate(263deg);
  transform: rotate(263deg);
  -ms-animation: IEfallback 1.9s infinite linear;
}
.lf-spinner-neg-xs svg {
  display: block;
  width: 25px;
  height: 25px;
}
.lf-spinner-neg-xs .lf-spinner-dash {
  stroke-dasharray: 240, 60;
  stroke-dashoffset: -6;
  -ms-stroke-dasharray: 240, 60;
  -ms-stroke-dashoffset: -6;
  stroke: #e30613;
  fill: transparent;
  stroke-width: 9;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  animation: spinnerDrawDash 1.9s infinite cubic-bezier(.53, 0, .49, 1);
  -webkit-animation: spinnerDrawDash 1.9s infinite cubic-bezier(.53, 0, .49, 1);
}
.lf-spinner-neg-xs .static-circle {
  fill: transparent;
  stroke-dasharray: 314;
  stroke: transparent;
  stroke-width: 8;
}
.lf-spinner-neg-xs .lf-spinner-back-line,
.lf-spinner-neg-xs .lf-spinner-forward-line {
  stroke: #fff;
  stroke-width: 6px;
  -webkit-transform: rotate(52deg);
  transform: rotate(52deg);
  -ms-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50% !important;
  -webkit-transform-origin: center left;
  -webkit-animation: forwardLineRotate 1.9s infinite
    cubic-bezier(.51, .04, .46, .94);
  animation: forwardLineRotate 1.9s infinite cubic-bezier(.51, .04, .46, .94);
}
.lf-spinner-neg-xs .lf-spinner-back-line {
  stroke: #fff;
  -webkit-transform: rotate(7deg);
  transform: rotate(7deg);
  -webkit-animation: backLineRotate 1.9s infinite cubic-bezier(.53, 0, .49, 1);
  animation: backLineRotate 1.9s infinite cubic-bezier(.53, 0, .49, 1);
}
.lf-spinner-btn-primary {
  position: relative;
  display: inline-block;
  background-color: transparent;
  border: 5px solid transparent;
  border-radius: 50%;
  -webkit-transform: rotate(263deg);
  transform: rotate(263deg);
  -ms-animation: IEfallback 1.9s infinite linear;
  border: none;
  vertical-align: middle;
  margin-left: 18px;
}
.lf-spinner-btn-primary svg {
  display: block;
  width: 20px;
  height: 20px;
}
.lf-spinner-btn-primary .lf-spinner-dash {
  stroke-dasharray: 240, 60;
  stroke-dashoffset: -6;
  -ms-stroke-dasharray: 240, 60;
  -ms-stroke-dashoffset: -6;
  stroke: #fff;
  fill: transparent;
  stroke-width: 9;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  animation: spinnerDrawDash 1.9s infinite cubic-bezier(.53, 0, .49, 1);
  -webkit-animation: spinnerDrawDash 1.9s infinite cubic-bezier(.53, 0, .49, 1);
}
.lf-spinner-btn-primary .static-circle {
  fill: transparent;
  stroke-dasharray: 314;
  stroke: transparent;
  stroke-width: 8;
}
.lf-spinner-btn-primary .lf-spinner-back-line,
.lf-spinner-btn-primary .lf-spinner-forward-line {
  stroke: #fff;
  stroke-width: 6px;
  -webkit-transform: rotate(52deg);
  transform: rotate(52deg);
  -ms-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50% !important;
  -webkit-transform-origin: center left;
  -webkit-animation: forwardLineRotate 1.9s infinite
    cubic-bezier(.51, .04, .46, .94);
  animation: forwardLineRotate 1.9s infinite cubic-bezier(.51, .04, .46, .94);
}
.lf-spinner-btn-primary .lf-spinner-back-line {
  stroke: #fff;
  -webkit-transform: rotate(7deg);
  transform: rotate(7deg);
  -webkit-animation: backLineRotate 1.9s infinite cubic-bezier(.53, 0, .49, 1);
  animation: backLineRotate 1.9s infinite cubic-bezier(.53, 0, .49, 1);
}
.lf-spinner-btn-secondary {
  position: relative;
  display: inline-block;
  background-color: transparent;
  border: 5px solid transparent;
  border-radius: 50%;
  -webkit-transform: rotate(263deg);
  transform: rotate(263deg);
  -ms-animation: IEfallback 1.9s infinite linear;
}
.lf-spinner-btn-secondary svg {
  display: block;
  width: 20px;
  height: 20px;
}
.lf-spinner-btn-secondary .lf-spinner-dash {
  stroke-dasharray: 240, 60;
  stroke-dashoffset: -6;
  -ms-stroke-dasharray: 240, 60;
  -ms-stroke-dashoffset: -6;
  stroke: #00427a;
  fill: transparent;
  stroke-width: 9;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  animation: spinnerDrawDash 1.9s infinite cubic-bezier(.53, 0, .49, 1);
  -webkit-animation: spinnerDrawDash 1.9s infinite cubic-bezier(.53, 0, .49, 1);
}
.lf-spinner-btn-secondary .static-circle {
  fill: transparent;
  stroke-dasharray: 314;
  stroke: transparent;
  stroke-width: 8;
}
.lf-spinner-btn-secondary .lf-spinner-back-line,
.lf-spinner-btn-secondary .lf-spinner-forward-line {
  stroke: #fff;
  stroke-width: 6px;
  -webkit-transform: rotate(52deg);
  transform: rotate(52deg);
  -ms-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50% !important;
  -webkit-transform-origin: center left;
  -webkit-animation: forwardLineRotate 1.9s infinite
    cubic-bezier(.51, .04, .46, .94);
  animation: forwardLineRotate 1.9s infinite cubic-bezier(.51, .04, .46, .94);
}
.lf-spinner-btn-secondary .lf-spinner-back-line {
  stroke: #fff;
  -webkit-transform: rotate(7deg);
  transform: rotate(7deg);
  -webkit-animation: backLineRotate 1.9s infinite cubic-bezier(.53, 0, .49, 1);
  animation: backLineRotate 1.9s infinite cubic-bezier(.53, 0, .49, 1);
}

@-webkit-keyframes spinnerDrawDash {
  0% {
    stroke-dasharray: 36, 257;
    stroke-dashoffset: -6;
  }
  25% {
    stroke-dashoffset: -6;
  }
  30% {
    stroke-dasharray: 240, 60;
  }
  to {
    stroke-dasharray: 38, 258;
    stroke-dashoffset: -301;
  }
}
@keyframes spinnerDrawDash {
  0% {
    stroke-dasharray: 36, 257;
    stroke-dashoffset: -6;
  }
  25% {
    stroke-dashoffset: -6;
  }
  30% {
    stroke-dasharray: 240, 60;
  }
  to {
    stroke-dasharray: 38, 258;
    stroke-dashoffset: -301;
  }
}
@-webkit-keyframes forwardLineRotate {
  0% {
    -webkit-transform: rotate(52deg);
    transform: rotate(52deg);
  }
  30% {
    -webkit-transform: rotate(305deg);
    transform: rotate(305deg);
  }
  87% {
    -webkit-transform: rotate(406deg);
    transform: rotate(406deg);
  }
  to {
    -webkit-transform: rotate(409deg);
    transform: rotate(409deg);
  }
}
@keyframes forwardLineRotate {
  0% {
    -webkit-transform: rotate(52deg);
    transform: rotate(52deg);
  }
  30% {
    -webkit-transform: rotate(305deg);
    transform: rotate(305deg);
  }
  87% {
    -webkit-transform: rotate(406deg);
    transform: rotate(406deg);
  }
  to {
    -webkit-transform: rotate(409deg);
    transform: rotate(409deg);
  }
}
@-webkit-keyframes backLineRotate {
  0% {
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg);
  }
  25% {
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg);
  }
  to {
    -webkit-transform: rotate(366deg);
    transform: rotate(366deg);
  }
}
@keyframes backLineRotate {
  0% {
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg);
  }
  25% {
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg);
  }
  to {
    -webkit-transform: rotate(366deg);
    transform: rotate(366deg);
  }
}
@-webkit-keyframes IEfallback {
  0% {
    -webkit-transform: rotate(233deg);
    transform: rotate(233deg);
  }
  to {
    -webkit-transform: rotate(593deg);
    transform: rotate(593deg);
  }
}
@keyframes IEfallback {
  0% {
    -webkit-transform: rotate(233deg);
    transform: rotate(233deg);
  }
  to {
    -webkit-transform: rotate(593deg);
    transform: rotate(593deg);
  }
}
