.button {
  position: relative;
  background-color: #a02520;
  color: #fff;
  border: none;
  font-size: 18px;
  border: 0;
  border-radius: 0;
  outline: none;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.button:hover {
  background-color: #910d12;
  cursor: pointer;
}
