h1,
h2,
h3,
h4,
h5 {
  font-family: IntroCondBold, Arial, "Helvetica Neue", sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  -webkit-font-kerning: normal;
  font-kerning: normal;
  -webkit-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
}

h1 {
  margin: 0;
  padding: 0;
  font-size: 2.25em;
  line-height: 1.35;
  color: #00427a;
}

h2 {
  margin: 0;
  padding: 0;
  font-size: 1.250em;
  line-height: 1.35;
  color: #00427a;
}

h3 {
  font-size: 1.17em;
  margin: 0;
  padding: 0;
  line-height: 1.3;
  color: #00427a;
}

h4 {
  font-size: 1em;
  margin: 0;
  padding: 0;
  line-height: 1.3;
}

h5 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 1.3;
}
button {
  font-family: IntroCondBold, Arial, "Helvetica Neue", sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  -webkit-font-kerning: normal;
  font-kerning: normal;
  -webkit-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
}

textarea {
  border-radius: 0;
}

.site {
  font-family: Arial, "Helvetica Neue", sans-serif;
}
