.signature-container {
}
.signing-in-progress__header {
  color: #00427a;
  border-bottom: 2px solid #f3f3f3;
  padding: 20px;
  font-size: 23px;
  line-height: 30px;
}

.signing-in-progress__content {
  padding: 30px 20px;
}

.signing-in-progress_cancel_message {
    font-size:12px;
    font-weight:bold;
}

.signing-in-progress__content__bankid-logo {
}

.signing-in-progress__content__status-message {
  color: #222;
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 22px;
  margin: 20px 0;
  font-style: italic;
}

.signing-in-progress__content__loader {
  border-radius: 50px;
}

.signing-in-progress__content__link {
  display: inline-block;
  color: #ad1015;
  font-family: IntroCondBold, Arial, "Helvetica Neue", sans-serif;
  text-decoration: none;
  margin-top: 10px;
}

div.qr-container, div.same-device-container {
    width: 100%;
    display: flex;
    margin-left:0px;
    justify-content: left;
}

div.same-device-container a {
  font-family: Arial, Helvetica, sans-serif;
  color:#00427a;
  font-weight: normal;
}

div.same-device-container a.signing-in-progress__content__link {
  color: #ad1015;
  font-family: IntroCondBold, Arial, "Helvetica Neue", sans-serif;
  text-decoration: none;

}

div.same-device-container a.start-bankid-link{
  border: 2px solid #00427a;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
}

div.qr-container div Ul{
    margin-left:0px;
    padding-left:15px;

}

div.qr-container div LI{
margin-top:5px;
}

div.info{
    margin-top:10px;
}

button.linkbutton {
  border:none;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  background-color: transparent;
  margin-top:5px;
  font-size:1rem;
  color:#00427a;
  font-weight: normal;
  text-decoration: underline;
  padding-left: 0;
}

.launch-button{
  background-color: #fff;
  color: #00417c;
  border: 2px solid #00467f;
  padding: 10px 15px;
  margin-top: 18px;
  font-size: 16px;
  cursor:pointer;
}