$grid-spacing: 20px;

$col-2-width: 8.3333333333333333%;
$col-2-width: 16.666666666666667%;
$col-4-width: 33.333333333333333%;
$col-5-width: 41.666666666666665%;
$col-6-width: 50%;
$col-7-width: 58.333333333333333%;
$col-8-width: 66.666666666666666%;
$col-12-width: 100%;

* {
  box-sizing: border-box;
}

.col {
  font-size: initial;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 $grid-spacing 0 $grid-spacing;
}

// Columns

.col-2 {
  width: $col-2-width;
}

.col-4 {
  width: $col-4-width;
}

.col-5 {
  width: $col-5-width;
}

.col-6 {
  width: $col-6-width;
}

.col-7 {
  width: $col-7-width;
}

.col-8 {
  width: $col-8-width;
}

.col-12 {
  width: $col-12-width;
}

// Offset

.col-offset-8 {
  margin-left: $col-8-width;
}

// Utility

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}
