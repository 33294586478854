
.test-mode-indicator__wrapper {
  padding: 25px 15px;
}

.test-mode-indicator__container {
  border: 4px solid #0265aa;
  padding: 25px;
  position: relative;
}

.test-mode-indicator__header {
	font-family: 'IntroCondBold', Arial, "Helvetica Neue", sans-serif;
  margin: 0;
  color: #005aa0;
  font-size: 1.17em;
  padding: 0;
  line-height: 1.3;
}

.test-mode-indicator__red-corner {
  width: 24px;
  position: absolute;
  top: 4px;
  left: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.test-mode-indicator__content {
  border-top: 2px solid #d9d9d9;
  padding-top: 10px;
  margin-top: 10px;
}


.signature-test-mode-indicator__text {
  font-family: 'IntroCondRegular', Arial, "Helvetica Neue", sans-serif;
  display: inline-block;
}

.signature-test-mode-indicator__fake-signature-button {
  background-color: #fff;
  color: #00417c;
  border: 2px solid #00467f;
  padding: 10px 15px;
  float:right;
  margin-top: 18px;
  font-size: 16px;
}

.signature-test-mode-indicator__fake-signature-button:hover {
  background-color: #fff;
}

.signature-test-mode-indicator__fake-signature-button:disabled {
  cursor: unset;
  border-color: #fff;
}

.signature-test-mode-indicator__clear {
  clear: both;
}

